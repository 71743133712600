import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGshare = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      data-testid='share-svg'
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M25 0H45C47.7614 0 50 2.23858 50 5V25H45V9.00425L22.7969 31.1757L18.7816 27.1557L40.9689 5H25V0Z' />
      <path d='M15 10H0V50H40V35H35V45H5L5 15H15V10Z' />
    </svg>
  )
}

export default SVGshare
