'use client'

import NextImage, { ImageLoaderProps } from 'next/image'
import React from 'react'
import { IImageProps, ImageObject, ImageSource } from './Image.interface'
import styles from './Image.module.scss'

function isImageObject(source: ImageSource): source is ImageObject {
  return typeof source !== 'string' && 'imageFile' in source
}

function Image({
  src,
  format,
  resizeAspectRatio,
  resizeFit,
  resizeFocus,
  alt,
  className,
  ...props
}: IImageProps) {
  if (!src) return null

  const isObj = isImageObject(src)
  const srcUrl = isObj ? src.imageFile.url : src
  const isSvg = srcUrl.endsWith('.svg')
  const hasDMImg = isObj && !!src.imageFileDarkMode?.url

  const customLoader = ({ src: srcVal, width, quality }: ImageLoaderProps) => {
    const ratio = resizeAspectRatio || (props.height
      ? Number(props.width) / Number(props.height)
      : 1)
    const height = width / ratio

    const values = {
      w: width?.toString(),
      q: quality?.toString() || '75',
      h: height?.toFixed(0),
      fm: isSvg ? '' : format || 'webp',
      fit: resizeFit,
      f: resizeFocus
    }

    const query = new URLSearchParams()
    Object.entries(values).forEach(([key, value]) => {
      if (value) query.append(key, value)
    })

    return `${srcVal}?${query.toString()}`
  }

  let altText = ''
  if (isObj && src?.altText) {
    altText = src.altText
  } else if (alt) {
    altText = alt
  }

  return (
    <>
      <NextImage
        loader={customLoader}
        src={srcUrl}
        data-testid={props['data-testid'] || 'nextimageid'}
        alt={altText}
        className={`${className || ''} ${hasDMImg ? styles.hideOnDark : ''}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {hasDMImg
        && (
          <NextImage
            loader={customLoader}
            src={src.imageFileDarkMode.url}
            data-testid={props['data-testid'] || 'nextimageid'}
            alt={altText}
            className={`${className || ''} ${styles.hideOnLight}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
    </>
  )
}
export default Image
