'use client'

import useTranslation from 'app/i18n/client'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share'
import SVGclassroom from '@/journey/Svgs/icons/classroom'
import SVGclose from '@/journey/Svgs/icons/close'
import SVGfacebook from '@/journey/Svgs/icons/facebook'
import SVGpinterest from '@/journey/Svgs/icons/pinterest'
import SVGshare from '@/journey/Svgs/icons/share'
import SVGtwitter from '@/journey/Svgs/icons/twitter'
import { IShareModalProps } from './Share.interface'
import styles from './Share.module.scss'
import Copy from './buttons/Copy/Copy.client'
import Mail from './buttons/Mail/Mail.client'
import trackEngagement from '../Snowplow/trackEngagement'
import useGlobalContext from '../GlobalContext/useGlobalContext'
import { EngagementPlatformTypes } from '../Snowplow/Field.types'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    minWidth: '380px',
    height: '',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: '52px',
    paddingBottom: '52px',
    paddingLeft: '32px',
    paddingRight: '32px',
    borderRadius: '0',
    borderWidth: '0',
  },
  overlay: { zIndex: 100 },
}

const unitLocation = 'modal'

function Share({
  open: openProp,
  onRequestClose,
  href,
  title,
  shareTitle,
  image,
  snowplowEvents,
  showText,
  hideShareButton,
  ...props
}: IShareModalProps) {
  const [open, setOpen] = useState(openProp)
  const [isCopied, setIsCopied] = useState(false)
  const { pageTitle } = useGlobalContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (hideShareButton) {
      setOpen(openProp)
    }
  }, [openProp])

  const unitName = 'share'
  const sharedTitle = shareTitle || pageTitle

  const itemClicked =
    (platform: EngagementPlatformTypes, positionInUnit: number) => () => {
      trackEngagement({
        eventType: 'share',
        platform,
        unitName,
        unitLocation,
        positionInUnit,
        componentId: snowplowEvents?.componentId || null,
      })
    }

  const openModal = () => {
    setOpen(true)
    trackEngagement({
      eventType: 'share',
      unitName,
      unitLocation: snowplowEvents?.unitLocation || 'inline',
      positionInUnit: 5,
      componentId: snowplowEvents?.componentId || null,
    })
  }

  const closeModal = () => {
    onRequestClose?.()
    setOpen(false)
    trackEngagement({
      eventType: 'dismiss',
      unitName,
      unitLocation,
      componentId: snowplowEvents?.componentId || null,
    })
  }

  const copyClicked = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <>
      {!hideShareButton && (
        <div className='d-sm-inline-flex p-sm-relative vertical-align-sm-top Btn-link'>
          <button
            onClick={openModal}
            className='social-btn hover-opacity-50 Btn-link target p-sm-3'
            aria-label={t('Journey.Share.Title')}
            data-testid='shareModal-button'
            type='button'
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            <SVGshare className='vicon d-sm-block' />
            {showText && (
              <span className='d-sm-block f-sm-3 ml-sm-3'>
                {t('Journey.Share.Share')}
              </span>
            )}
          </button>
        </div>
      )}
      <Modal
        isOpen={open}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={t('Journey.Share.ModalLabel')}
      >
        <div
          data-testid='shareModal-contents'
          className='bg-background-high-white mx-sm-n5 px-sm-5 my-sm-n6 py-sm-6'
        >
          <button
            className='Btn-link target flex-sm-align-center flex-sm-justify-center p-sm-absolute right-sm-0 top-sm-0 text-foreground-darkblue'
            onClick={closeModal}
            aria-label={t('Journey.Common.Close')}
            data-testid='shareModal-close'
            type='button'
          >
            <SVGclose className='vicon m-sm-4' />
          </button>
          <p>
            <strong className='text-foreground-midblue d-sm-block'>
              {t('Journey.Share.Title')}
            </strong>
            {(title || sharedTitle) && (
              <span className='d-sm-block f-sm-5 lh-condensed heading pb-sm-5 pt-sm-3 text-bold text-foreground-midblue'>
                {title || sharedTitle}
              </span>
            )}
          </p>
          <ul className='list-reset p-md-0 m-md-0 '>
            <li className='border-sm-bottom border-sm-top border-static-grey3 d-sm-flex'>
              <FacebookShareButton
                url={href}
                className='w-sm-full'
                onClick={itemClicked('facebook', 1)}
                aria-label={t('Journey.Share.Facebook')}
                data-testid='shareModal-facebook'
              >
                <div className='d-sm-inline-flex hover-opacity-50 py-sm-4 bg-gray flex-sm-align-center flex-sm-justify-start w-sm-full'>
                  <SVGfacebook className='vicon' />
                  <span className='f-sm-2 ml-sm-3 text-bold'>
                    {t('Journey.Share.Facebook')}
                  </span>
                </div>
              </FacebookShareButton>
            </li>
            <li className='border-sm-bottom border-static-grey3 d-sm-flex'>
              <TwitterShareButton
                url={href}
                className='w-sm-full'
                onClick={itemClicked('twitter', 2)}
                aria-label={t('Journey.Share.Twitter')}
                data-testid='shareModal-twitter'
              >
                <div className='d-sm-inline-flex hover-opacity-50 py-sm-4 bg-gray flex-sm-align-center flex-sm-justify-start w-sm-full'>
                  <SVGtwitter className='vicon' />
                  <span className='f-sm-2 ml-sm-3 text-bold'>
                    {t('Journey.Share.Twitter')}
                  </span>
                </div>
              </TwitterShareButton>
            </li>
            <li className='border-sm-bottom border-static-grey3 d-sm-flex'>
              <PinterestShareButton
                url={href}
                media={
                  image ||
                  'https://images.ctfassets.net/p0qf7j048i0q/7JP9b3sdgeyq7UrDrnWg1w/489338ae0b7c2e5a1c504f1961475847/1.png?w=1000&fm=webp'
                }
                className='w-sm-full'
                onClick={() => itemClicked('pinterest', 3)}
                aria-label={t('Journey.Share.Pinterest')}
                data-testid='shareModal-pinterest'
              >
                <div className='d-sm-inline-flex hover-opacity-50 py-sm-4 bg-gray flex-sm-align-center flex-sm-justify-start w-sm-full'>
                  <SVGpinterest className='vicon' />
                  <span className='f-sm-2 ml-sm-3 text-bold'>
                    {t('Journey.Share.Pinterest')}
                  </span>
                </div>
              </PinterestShareButton>
            </li>
            <li className='border-sm-bottom border-static-grey3'>
              <div className='p-sm-relative d-sm-flex hover-opacity-50 py-sm-4 bg-gray flex-sm-align-center flex-sm-justify-start w-sm-full'>
                <Mail
                  title={sharedTitle || title || ''}
                  url={href}
                  className={styles.buttons}
                  snowplowEvents={{
                    unitLocation,
                    positionInUnit: 4,
                    componentId: snowplowEvents?.componentId || null,
                  }}
                />
                <span className='f-sm-2 ml-sm-3 text-bold'>
                  {t('Journey.Share.Email')}
                </span>
              </div>
            </li>
            <li className='border-sm-bottom border-static-grey3'>
              <div className='p-sm-relative d-sm-flex hover-opacity-50 py-sm-4 bg-gray flex-sm-align-center flex-sm-justify-start w-sm-full'>
                <Copy
                  url={href}
                  className={styles.buttons}
                  hideCopiedText
                  snowplowEvents={{
                    unitLocation,
                    positionInUnit: 5,
                    componentId: snowplowEvents?.componentId || null,
                  }}
                  onCopy={copyClicked}
                />
                <span className='f-sm-2 ml-sm-3 text-bold'>
                  {isCopied
                    ? t('Journey.Share.Copied')
                    : t('Journey.Share.Copy')}
                </span>
              </div>
            </li>
            <li className='border-sm-bottom border-static-grey3 d-sm-flex'>
              <a
                href={`https://classroom.google.com/u/0/share?url=${href}`}
                className='w-sm-full Btn-link hover-opacity-50'
                target='blank'
                onClick={itemClicked('google_classroom', 6)}
                aria-label={t('Journey.Share.GoogleClassroomLabel')}
                data-testid='shareModal-googleClassroom'
              >
                <button
                  type='button'
                  className='Btn-link hover-opacity-50 py-sm-4 bg-gray flex-sm-align-center flex-sm-justify-start w-sm-full text-foreground-darkblue'
                  aria-label={t('Journey.Share.GoogleClassroom')}
                >
                  <SVGclassroom className='vicon' />
                  <span className='f-sm-2 ml-sm-3 text-bold text-foreground-darkblue'>
                    {t('Journey.Share.GoogleClassroom')}
                  </span>
                </button>
                <span className='sr-only'>{t('Journey.Common.NewTab')}</span>
              </a>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  )
}

export default Share
