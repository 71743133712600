import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGpinterest = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      data-testid='pinterest-svg'
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9056 34.5332C24.6046 36.893 27.3737 38.2438 30.2793 38.1301C39.8112 38.1301 46.8252 29.3176 46.8252 18.347C46.8252 7.73601 38.1926 0.00260151 27.0421 0.00260151C13.1939 -0.177245 6 8.99494 6 19.0664C6 23.7424 8.51785 29.4975 12.4745 31.4758C13.1939 31.8355 13.3737 31.6556 13.5536 31.1161C13.7334 30.5765 14.273 28.4184 14.4528 27.5192C14.4997 27.2076 14.4361 26.8895 14.273 26.6199C12.6768 24.4999 11.852 21.8986 11.9349 19.2462C11.9372 15.4796 13.4716 11.876 16.1854 9.26397C18.8991 6.65196 22.5588 5.25633 26.3227 5.398C34.2359 5.398 39.6313 10.7934 39.6313 18.347C39.6313 26.9796 35.315 32.9146 29.5599 32.9146C28.1079 32.9539 26.7201 32.3162 25.8042 31.1889C24.8882 30.0616 24.5482 28.5726 24.8839 27.1595C25.7831 23.3827 27.5816 19.2462 27.5816 16.5485C27.7062 15.3853 27.3235 14.2248 26.5314 13.3638C25.7393 12.5029 24.6147 12.025 23.4451 12.0523C20.2079 12.0523 17.69 15.4694 17.69 19.7857C17.6546 21.3906 17.9612 22.9845 18.5893 24.4618C18.5893 24.4618 15.352 37.7704 14.8125 40.2883C14.3051 43.1996 14.2445 46.1712 14.6326 49.1008C15.352 49.4605 16.2513 49.6403 16.9707 50C18.5501 47.4638 19.8182 44.7465 20.7474 41.9069C21.287 40.4681 22.9056 34.5332 22.9056 34.5332Z'
      />
    </svg>
  )
}

export default SVGpinterest
