'use client'

import Modal from 'react-modal'
import SVGclose from '@fractal/primitives/SVGs/icons/close'
import styles from './PromptModal.module.scss'

interface IPromptModalProps {
  open: boolean
  onRequestClose: () => void
  children: React.ReactNode
}

function PromptModal({ open, onRequestClose, children }: IPromptModalProps) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => onRequestClose()}
      className={styles.promptModalContent}
      overlayClassName={styles.promptModalOverlay}
      contentLabel='Share modal'
    >
      <div className='h-sm-full d-sm-flex flex-sm-justify-center flex-sm-align-center'>
        <div data-testid='promptModal-contents'>
          <button
            aria-label='Close question window'
            data-testid='promptModal-close'
            onClick={() => onRequestClose()}
            className='Btn-link target flex-sm-align-center flex-sm-justify-center p-sm-absolute right-sm-0 top-sm-0 z-index-1'
            type='button'
          >
            <SVGclose className='vicon m-sm-4' />
          </button>
          {children}
        </div>
      </div>
    </Modal>
  )
}

export default PromptModal
