interface IBlockContentData {
  titleBold: {
    en: string
    'es-mx': string
  }
  titleRegular: {
    en: string
    'es-mx': string
  }
  action: {
    en: {
      print: string
      download: string
    }
    'es-mx': {
      print: string
      download: string
    }
  }
  form: {
    en: {
      fieldLabel: string
      fielErrorMessage: string
      buttonLabel: string
      emailTags: string[]
    }
    'es-mx': {
      fieldLabel: string
      fielErrorMessage: string
      buttonLabel: string
      emailTags: string[]
    }
  }
}

export const copy: IBlockContentData = {
  titleBold: {
    en: 'Enjoying this article? ',
    'es-mx': '¿Le gustó este artículo? ',
  },
  titleRegular: {
    en: 'We’ll email you our most helpful stories and resources.',
    'es-mx':
      'Le enviaremos por correo electrónico nuestras historias y recursos más útiles.',
  },
  action: {
    en: {
      print: 'No thanks, I’ll just print the article',
      download: 'No thanks, I’ll just download',
    },
    'es-mx': {
      print: 'No gracias, solo imprimiré el artículo',
      download: 'No gracias, solo lo descargaré',
    },
  },
  form: {
    en: {
      fielErrorMessage: 'Please enter a valid email address',
      fieldLabel: 'Email address',
      buttonLabel: 'Subscribe',
      emailTags: ['program_families'],
    },
    'es-mx': {
      fieldLabel: 'Correo electrónico',
      fielErrorMessage: 'Please enter a valid email address',
      buttonLabel: 'Continúa',
      emailTags: ['program_families'],
    },
  },
}
