'use client'

import SVGemail from '@/journey/Svgs/icons/email'
import useTranslation from 'app/i18n/client'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import { IMailProps } from './Mail.interface'

function Mail({
  title,
  url,
  onEmail,
  className,
  snowplowEvents,
  ...props
}: IMailProps) {
  const { t } = useTranslation()

  return (
    <TrackClick
      as='a'
      data-testid='socialShare-email-button'
      href={`mailto:?subject=${title || ''}&body=${url}`}
      target='blank'
      onClick={onEmail}
      aria-label={t('Journey.Mail.Label')}
      className={`social-btn hover-opacity-50 target p-sm-3 ${className} d-sm-block`}
      isEngagementEvent
      trackProps={{
        positionInUnit: snowplowEvents?.positionInUnit || undefined,
        eventType: 'share',
        platform: 'email',
        unitName: 'share',
        unitLocation: snowplowEvents?.unitLocation || 'inline',
        componentId: snowplowEvents?.componentId || null,
      }}
      {...props}
    >
      <SVGemail className='vicon d-sm-block' />
    </TrackClick>
  )
}

export default Mail
