'use client'

import { useState } from 'react'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'

import SVGemail from '@fractal/primitives/SVGs/socials/email'
import axios from 'axios'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import { Label } from '@fractal/primitives/atoms/forms/Label'
import { Input } from '@fractal/primitives/atoms/forms/Input'
import { FormError } from '@fractal/primitives/atoms/forms/FormError'

const downloadablesJune2024 = [
  '/en/articles/evidence-based-behavior-strategy-brain-breaks',
  '/en/articles/behavior-strategy-when-then',
  '/en/articles/evidence-based-literacy-strategy-elkonin-sound-boxes',
  '/en/articles/empathetic-sentence-starters-for-teachers',
  '/en/articles/what-can-teachers-say-when-families-raise-concerns-about-their-child',
  '/en/articles/fact-fluency-an-evidence-based-math-strategy',
  '/en/articles/number-representations-an-evidence-based-math-strategy',
  '/en/articles/dividing-fractions-using-fraction-strips-an-evidence-based-math-strategy',
  '/en/articles/place-value-disks-an-evidence-based-math-strategy',
  '/en/articles/place-value-with-straw-bundles-an-evidence-based-math-strategy',
  '/en/articles/graphic-organizers-for-reading',
  '/en/articles/how-to-teach-retelling',
  '/en/articles/lesson-planning-with-universal-design-for-learning-udl',
  '/en/articles/how-to-teach-using-explicit-instruction',
  '/en/articles/download-3x3-card-to-help-teachers-get-to-know-your-child',
  '/en/articles/evidence-based-math-strategy-fraction-number-lines',
  '/en/articles/how-to-use-accommodations-and-modifications-in-the-classroom',
  '/en/articles/7-ways-to-respond-to-students-with-empathy',
  '/en/articles/navigating-individualized-education-programs-ieps-your-roadmap-to-equitable-access',
  '/en/articles/organization-printables-resources-for-families',
  '/en/articles/sel-printables-to-share-with-your-students-families',
  '/en/articles/live-video-lessons-5-ways-kids-struggle-and-how-to-help',
  '/printable-back-to-school-update-to-learn-from-families',
  '/questionnaires-for-connecting-with-students-and-families',
  '/5-tips-for-teacher-collaboration-when-students-struggle',
  '/effective-praise-guide-for-teachers',
]

const checkList = (): boolean => {
  const currentPath = window.location.pathname
  if (downloadablesJune2024.includes(currentPath)) {
    return true
  }
  return false
}
const educatorsData = { lists: ['misc'], roles: ['educators'] }

interface IEmailFormTranslations {
  fieldLabel: string
  fielErrorMessage: string
  buttonLabel: string
  emailTags: string[]
}

interface IEmailFormProps {
  translations: IEmailFormTranslations
  unitLocation: 'inline' | 'modal'
  onSubmit: () => void
}

export function EmailForm({
  translations,
  onSubmit,
  unitLocation,
}: IEmailFormProps) {
  const [email, setEmail] = useState('')
  const [error, toggleEmailError] = useState(false)
  const globalContext = useGlobalContext()
  const { locale, slug } = globalContext

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value)
    toggleEmailError(false)
  }

  const submitForm: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    if (!isValidEmail) {
      toggleEmailError(true)
      return
    }

    trackEngagement({
      eventType: 'subscribe',
      submittedText: email,
      submitSuccess: true,
      unitName: 'account_gate',
      unitLocation,
      componentId: null,
    })

    const tags = Array.from(new Set(translations.emailTags))
    const roles = checkList() ? educatorsData.roles : tags
    const lists = checkList() ? educatorsData.lists : []
    await axios({
      url: `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${locale}/subscribe`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email,
        tag: tags,
        web_user_selected_roles: roles,
        join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${slug}`,
        web_subscription_list: lists,
      },
    }).then(() => {
      onSubmit()
    })
  }

  return (
    <div className='grid-col-sm-12'>
      <div className='grid grid-gap-none-sm '>
        <div className='grid-col-sm-12'>
          <TrackImpression
            as='fragment'
            unitName='account_gate'
            buttonName={null}
            unitLocation={unitLocation}
            componentId={null}
          />
          <form onSubmit={submitForm}>
            <FormControl>
              <Label htmlFor='email'>{translations.fieldLabel}</Label>
              <Input
                id='email'
                type='email'
                value={email}
                required
                onChange={handleEmailChange}
              />
              <FormError>
                {error ? translations.fielErrorMessage : ''}
              </FormError>
            </FormControl>

            <div>
              <button className='Btn Btn--shadow Btn--responsive' type='submit'>
                <SVGemail className='mr-sm-2 w-sm-4 fill-foreground-darkblueButton' />{' '}
                {translations.buttonLabel}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
