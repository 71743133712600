import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGclassroom = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      data-testid='classroom-svg'
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M25.4984 21.4266V30H39.6781C39.1015 33.6813 35.3906 40.7812 25.4984 40.7812C16.9625 40.7812 9.99998 33.7109 9.99998 25C9.99998 16.2891 16.9625 9.21875 25.4984 9.21875C30.3515 9.21875 33.6047 11.2891 35.4594 13.0766L42.2469 6.54375C37.8906 2.4625 32.2469 0 25.4984 0C11.6797 0 0.498413 11.1813 0.498413 25C0.498413 38.8187 11.6797 50 25.4984 50C39.9219 50 49.5031 39.8531 49.5031 25.5766C49.5031 23.9359 49.3265 22.6859 49.1125 21.4359L25.4984 21.4266Z' />
    </svg>
  )
}

export default SVGclassroom
