import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGcopyLink = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='copylink-svg'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.8524 27.2019L42.5029 19.5514C45.8316 16.2227 45.8316 10.8258 42.5029 7.4971C39.1742 4.16839 33.7773 4.16839 30.4486 7.4971L28.739 9.20671C28.1733 9.77234 27.3712 10.0332 26.581 9.90843C24.5626 9.58972 23.7581 7.1157 25.203 5.67077L26.9126 3.96117C32.1942 -1.32039 40.7573 -1.32039 46.0388 3.96117C51.3204 9.24272 51.3204 17.8058 46.0388 23.0874L38.3883 30.7379C33.1068 36.0194 24.5437 36.0194 19.2621 30.7378C19.0704 30.5461 18.8856 30.3501 18.7078 30.15C17.9677 29.3173 18.107 28.0678 18.8948 27.28L19.2493 26.9255C20.2062 25.9685 21.8411 26.245 22.7981 27.2019C26.1268 30.5306 31.5237 30.5306 34.8524 27.2019ZM15.1476 22.7981L7.49711 30.4486C4.16839 33.7773 4.16839 39.1742 7.49711 42.5029C10.8258 45.8316 16.2227 45.8316 19.5514 42.5029L21.2611 40.7932C21.8268 40.2276 22.6289 39.9667 23.4191 40.0915C25.4375 40.4102 26.242 42.8842 24.7971 44.3291L23.0874 46.0388C17.8058 51.3204 9.24273 51.3204 3.96117 46.0388C-1.32039 40.7573 -1.32039 32.1942 3.96117 26.9126L11.6117 19.2621C16.8932 13.9806 25.4563 13.9806 30.7379 19.2622C30.9296 19.4539 31.1143 19.6499 31.2921 19.8499C32.0322 20.6827 31.893 21.9322 31.1051 22.72L30.7506 23.0745C29.7937 24.0314 28.1589 23.755 27.2019 22.7981C23.8732 19.4694 18.4763 19.4694 15.1476 22.7981Z'
      />
    </svg>
  )
}

export default SVGcopyLink
