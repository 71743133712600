'use client'

import SVGcopyLink from '@/journey/Svgs/icons/copyLink'
import useTranslation from 'app/i18n/client'
import { useState } from 'react'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import { ICopyProps } from './Copy.interface'
import styles from './Copy.module.scss'

function Copy({
  url,
  onCopy,
  className,
  hideCopiedText,
  snowplowEvents,
  ...props
}: ICopyProps) {
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  const copyToClipboard = async () => {
    try {
      await window.navigator.clipboard.writeText(url)
      onCopy?.()
      if (!hideCopiedText) {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      }
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <TrackClick
      as='button'
      type='button'
      data-testid='socialShare-copy-button'
      onClick={copyToClipboard}
      className={`social-btn hover-opacity-50 Btn-link flex-sm-align-center target p-sm-3 ${className}`}
      aria-label={t('Journey.Copy.Label')}
      isEngagementEvent
      trackProps={{
        positionInUnit: snowplowEvents?.positionInUnit || undefined,
        eventType: 'share',
        platform: 'copy_link' as any,
        unitName: 'share',
        unitLocation: snowplowEvents?.unitLocation || 'inline',
        componentId: snowplowEvents?.componentId || null,
      }}
      {...props}
    >
      <SVGcopyLink className='d-sm-block mx-auto my-sm-0 vertical-align-sm-middle vicon' />
      {copied && (
        <p className={`p-sm-absolute ${styles.text}`}>
          {t('Journey.Copy.Copied')}
        </p>
      )}
    </TrackClick>
  )
}

export default Copy
