'use client'

import { UserContext } from '@context/UserContext'
import { createElement, useContext, useState } from 'react'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import PromptModal from 'app/components/PromptModal/PromptModal.client'
import { EmailForm } from './EmailForm.client'
import { copy } from './BlockContent.data'

interface IBlockContentProps
  extends Omit<Partial<HTMLAnchorElement & HTMLButtonElement>, 'children'> {
  onClick: React.MouseEventHandler<HTMLAnchorElement & HTMLButtonElement>
  onClickDelayed: () => void
  isDownload?: boolean
  as: React.ElementType
  children: React.ReactNode
  dataTestId?: string
}

export function BlockContent({
  onClick,
  onClickDelayed,
  isDownload,
  as = 'a',
  children,
  href,
  target,
  className,
  ariaLabel,
  dataTestId,
}: IBlockContentProps) {
  const { isAuthenticated } = useContext(UserContext)
  const globalContext = useGlobalContext()
  const localePath = globalContext.locale
  const [openQ, toggleOpenQ] = useState(false)

  const myCustomOnClick: React.MouseEventHandler<
    HTMLAnchorElement & HTMLButtonElement
  > = (e) => {
    onClick(e)
    if (!isAuthenticated) {
      toggleOpenQ(true)
    } else {
      onClickDelayed()
    }
  }

  const Component = createElement(
    as,
    {
      href,
      target,
      className,
      'aria-label': ariaLabel,
      'data-testid': dataTestId,
      onClick: myCustomOnClick,
    },
    children
  )

  const onClosePromptModal = () => {
    toggleOpenQ(false)
    trackEngagement({
      eventType: 'dismiss',
      unitName: 'account_gate',
      unitLocation: 'modal',
      componentId: null,
    })
  }

  const onSkipPromptModal = () => {
    toggleOpenQ(false)
    setTimeout(onClickDelayed, 1000)
    trackEngagement({
      eventType: 'skip',
      unitName: 'account_gate',
      unitLocation: 'modal',
      componentId: null,
    })
  }

  const onSubmitForm = () => {
    toggleOpenQ(false)
    setTimeout(onClickDelayed, 1000)
  }

  const SubscribeModal = (
    <PromptModal open={openQ} onRequestClose={onClosePromptModal}>
      <div>
        <div className='f-sm-5 f-md-6'>
          <p>
            <strong>{copy.titleBold[localePath]}</strong>{' '}
            {copy.titleRegular[localePath]}
          </p>
        </div>
        <div className='my-sm-5'>
          <EmailForm
            translations={copy.form[localePath]}
            onSubmit={onSubmitForm}
            unitLocation='modal'
          />
        </div>
        <button
          data-testid='promptModal-create'
          onClick={onSkipPromptModal}
          className='Btn-link text-bold f-sm-3 text-foreground-midblue text-underline'
          type='button'
        >
          {copy.action[localePath][isDownload ? 'download' : 'print']}
        </button>
      </div>
    </PromptModal>
  )

  return (
    <>
      {Component}
      {!isAuthenticated ? SubscribeModal : null}
    </>
  )
}
